<template>
	<div class="breadcrumb clearfix">
		<i class="el-icon-map-location"></i>
		当前位置：
		<span><router-link to="/">首页</router-link></span>
		<span class="symbol"> > </span>
		<span><router-link :to="routerPath">{{title}}</router-link></span>
		<span class="symbol" v-if="subtitle"> > </span>
		<span class="line1">{{subtitle}}</span>
	</div>
</template>

<script>
	export default {
		props: {
			title: String,
			subtitle: String,
			routerPath: String
		},
		data() {
			return {
			}
		}
	}
</script>

<style lang="scss">
	// 面包屑导航
	.breadcrumb{
		display: flex;
		align-items: center;
		background: #f5f5f5;
		font-size: 12px;
		color: $main-text-color-light;
		height: 38px;
		line-height: 38px;
		i{
			font-size: 14px;
			margin: 0 5px 0 0;
			font-weight: bold;
		}
		span{
			&:last-child{
				color: $main-color;
			}
			&.symbol{
				margin: 0 5px;
			}
			&.line1{
				display: inline-block;
				max-width: 200px;
			}
		}
	}
</style>