<template>
	<div class="product-list">
		<!-- 头部 -->
		<mainHeader></mainHeader>
		<!-- 导航 -->
		<navigation :categoryList="categoryList">
			<template slot="breadcrumb">
				<breadcrumb title="产品分类"  routerPath="/productlist" :subtitle="subtitle"></breadcrumb>
			</template>
		</navigation>
		<div class="wrapper">
			<!-- 筛选 -->
			<!-- <div class="product-screen clearfix">
				<ul>
					<li v-for="(item,index) in screenList" :key="index">
						<div class="bigclass">{{item.bigclass}}：</div>
						<div class="subclass" :class="{'height60':item.classShow}" :ref="index">
							<span v-for="(value,i) in item.subclass" :key="i" @click="subItem(item,i)" :class="{'active':item.subIndex == i}">{{value.name}}</span>
						</div>
						<div class="more" v-if="item.screenItemHeight > 60" @click="moreClick(item,index)">
							<span>更多</span>
							<i class="el-icon-arrow-down"></i>
						</div>
					</li>
				</ul>
			</div> -->
			<div class="product-screen2 clearfix">
				<div class="screen-btn" :class="{'screen-price':index ===2,'active':screenItemIndex===index}"
					v-for="(item,index) in screenList2" :key="index" @click="screenClick(index)">
					<span>{{item.name}}</span>
					<i class="el-icon-bottom" v-if="index<2"></i>
					<div class="right" v-if="index===2">
						<i class="el-icon-arrow-up" :class="{orange:sortPrice==0}"></i>
						<i class="el-icon-arrow-down" :class="{orange:sortPrice==1}"></i>
					</div>
				</div>
			</div>
			<!-- 产品 -->
			<div class="recommend-list clearfix">
				<el-empty :image-size="50" description="暂无产品" v-if="goodsList.data&&goodsList.data.length==0"></el-empty>
				<ul class="clearfix">
					<li v-for="(item,index) in goodsList.data" :key="index">
						<router-link :to="{ path: '/details', query: { id: item.goods_id }}">
							<img :src="item.goods_image" />
							<p class="line1" :title="item.goods_no"><span>备件号：</span>{{item.goods_no}}</p>
							<p class="line2 name"><span>名称：</span>{{item.goods_name}}</p>
							<!-- <p><span>适用车型：</span>{{item.type}}</p> -->
							<p class="p-bottom">
								<span class="price">￥{{item.goods_price_min}}</span>
								<!-- <em class="cart-icon" :class="[{'active':item.cartSelect},{'shake':item.cartSelect}]"
									@click.prevent="addCart(item,index)">
									<i class="iconfont cart">&#xe604;</i>
									<i class="iconfont select" v-if="item.cartSelect">&#xe6b2;</i>
								</em> -->
							</p>
						</router-link>
					</li>
				</ul>
			</div>
			<!-- 分页 -->
			<div class="paging">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
					:current-page.sync="currentPage" :page-size="15" layout="prev, pager, next, jumper" :total="goodsList.total">
				</el-pagination>
			</div>
		</div>
		<!-- 尾部 -->
		<mainFooter></mainFooter>
	</div>
</template>

<script>
	import * as GoodsApi from '@/api/goods'
	import * as CategoryApi from '@/api/category'
	import mainHeader from '../../components/header.vue'
	import navigation from '../../components/navigation.vue'
	import breadcrumb from '../../components/breadcrumb.vue'
	import mainFooter from '../../components/footer.vue'
	export default {
		components: {
			mainHeader,
			navigation,
			breadcrumb,
			mainFooter,
		},
		data() {
			return {
				subtitle:'',
				currentPage: 1,
				goodsList: [],
				screenItemIndex: 0,
				screenList2: [{
					name: "综合"
				}, {
					name: "销量"
				}, {
					name: "价格"
				}],
				screenList: [{
					classShow: '',
					bigclass: "品牌",
					screenItemHeight: '',
					subIndex: '-1',
					subclass: [{
						id: 1,
						name: '火炬（TORCH）'
					}, {
						id: 2,
						name: '皇驰'
					}, {
						id: 3,
						name: '电装（DENSO）'
					}, {
						id: 4,
						name: '福耀'
					}, {
						id: 5,
						name: '菲罗多（ferodo）'
					}, {
						id: 6,
						name: '摩特（MOTUL）'
					}, {
						id: 7,
						name: '壳牌（Shell）TEI'
					}, {
						id: 8,
						name: '火炬（TORCH）'
					}, {
						id: 9,
						name: '皇驰'
					}, {
						id: 10,
						name: '电装（DENSO）'
					}, {
						id: 11,
						name: '福耀'
					}, {
						id: 12,
						name: '菲罗多（ferodo）'
					}, {
						id: 13,
						name: '摩特（MOTUL）'
					}, {
						id: 14,
						name: '壳牌（Shell）TEI'
					}, {
						id: 15,
						name: '火炬（TORCH）'
					}, {
						id: 16,
						name: '皇驰'
					}, {
						id: 17,
						name: '电装（DENSO）'
					}, {
						id: 18,
						name: '福耀'
					}, {
						id: 19,
						name: '菲罗多（ferodo）'
					}, {
						id: 20,
						name: '摩特（MOTUL）'
					}, {
						id: 21,
						name: '壳牌（Shell）TEI'
					}]
				}, {
					classShow: '',
					bigclass: "价格",
					screenItemHeight: '',
					subIndex: '-1',
					subclass: [{
						id: 1,
						name: '0-100'
					}, {
						id: 2,
						name: '100-300'
					}, {
						id: 3,
						name: '300-500'
					}, {
						id: 4,
						name: '500-1000'
					}, {
						id: 5,
						name: '1000-2000'
					}, {
						id: 6,
						name: '2000以上'
					}]
				}],
				categoryId: 0,
				search: '',
				sortType: 'all',
				sortPrice: null,
				categoryList: []
			}
		},
		watch: {
			$route() {
				this.init()
			}
		},
		created() {
			this.init()
		},
		methods: {
			init() {
				this.getCategoryList()
				if (this.$route.query.categoryId) {
					this.categoryId = this.$route.query.categoryId
				}
				if (this.$route.query.search) {
					this.search = this.$route.query.search
					let historyWord = localStorage.getItem('search')
					if (historyWord) {
						historyWord = JSON.parse(historyWord)
					} else {
						historyWord = []
					}
					let index = historyWord.findIndex(item => item == this.search)
					if (index < 0) {
						historyWord.push(this.search)
						localStorage.setItem('search', JSON.stringify(historyWord))
					}
				}

				this.getGoods()
			},
			getCategoryList() {
				CategoryApi.list().then(response => {
					this.categoryList = response.data.list
					if(this.categoryId==0){
						this.subtitle=this.categoryList[0].name
					}else{
						this.categoryList.forEach(item=>{
							if(item.category_id==this.categoryId){
								return this.subtitle=item.name
							}
						})
					}
				}).catch(err => {
					console.log(err)
				})
			},
			getGoods() {
				let app = this
				const param = {
					sortType: app.sortType,
					sortPrice: Number(app.sortPrice),
					categoryId: app.categoryId || 0,
					goodsName: app.search || '',
					page: app.currentPage
				}

				GoodsApi.list(param).then(result => {
					// 合并新数据
					const newList = result.data.list
					app.goodsList = newList
					// console.log(newList)
					// app.goodsList = getMoreListData(newList, app.list, pageNo)
				})
			},
			subItem(item, i) {
				item.subIndex = i
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getGoods()
				console.log(`当前页: ${val}`);
			},
			addCart(item) {
				item.cartSelect = !item.cartSelect
				// event.preventDefault(); 
			},
			screenClick(index) {

				this.screenItemIndex = index
				// console.log(index)

				if (index == 0) {
					this.sortType = 'all'
					this.sortPrice =null
				} else if (index == 1) {
					this.sortType = 'sales'
					this.sortPrice =null
				} else {
					this.sortType = 'price'
					this.sortPrice = (this.sortPrice == 0 ? 1 : 0)
				}

				this.currentPage = 1
				this.getGoods()
			},
			screenItem() {
				let _this = this;
				_this.$nextTick(() => {
					_this.screenList.forEach((item, index) => {
						item.screenItemHeight = _this.$refs[index][0].offsetHeight
						// console.log(item.screenItemHeight)
						if (item.screenItemHeight > 60) {
							item.classShow = true
						} else {
							item.classShow = false
						}
					})
				})
			},
			moreClick(item) {
				item.classShow = !item.classShow
			}
		},
		mounted() {
			// this.screenItem()
		}
	}
</script>

<style lang="scss">
	@import "../../assets/css/product-list.scss"
</style>